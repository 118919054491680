html {
  /* font sizes */
  --12px: .75rem;
  --13px: .813rem;
  --15px: .9375rem;
  --18px: 1.125rem;
  --20px: 1.25rem;
  --25px: 1.563rem;
  --30px: 1.875rem;
  --32px: 2rem;
  --35px: 2.188rem;
  --48px: 3rem;
  --64px: 4rem;

  /* breakpoints */
  --small: 826px;

  /* text colors */
  --background-solid: #f9ffef;
  --gray-100: #404048;
  --gray-90: #8fe2d9;
  --gray-70: #616161;
  --gray-65: #757575;
  --gray-60: #9e9e9e;
  --input-border: #7879f1;
  --cider-flat: #f9ffef;

  /* iris */
  --iris-900: #5d5fef;
  --iris-800: #7379ff;
  --iris-700: #9092ff;
  --iris-600: #b4bbff;
  --iris-500: #909BFF;
  --iris-300: #dfdfff;
  --iris-200: #eaeafd;
  --iris-100: #f8f8ff;
  --poke: var(--iris-900);

  /* lime */
  --lime-900: #f16578;
  --lime-500: #bbecd2;
  --lime-200: #f6fff1;
  --lime-100: #fcfffd;

  /* pink */
  --pink-900: #ea73d0;
  --pink-800: #eecaff;
  --pink-700: #faeefc;

  /* orange */
  --orange-900: #ffcc83;
  --orange-800: #f8deb8;
  --orange-700: #eef9ca;

  /* erros */
  --error-900: #f16578;
  --error-800: #ffcad1;
  --error-700: #fff6ee;

  /* blue */
  --blue-900: #f16578;
  --blue-800: #f16578;
  --blue-700: #ebf4f6;

  /* mono */
  --mono-900: #292b39;
  --mono-700: #82828e;
  --mono-400: #aaaab8;
  --mono-300: #F0F0F3;
  --mono-200: #f8f8f8;
  --mono-100: #fdfffe;
  --mono-000: #ffffff;

  /* red */
  --red-900: #F16578;
  --red-200: #FFCAD1;

  /* green */
  --green-0: #f9fcf9;

  /* misc */
  --text-alert-green: #37C692;

  /* gradients */
  --background-cider: radial-gradient(
    45.37% 115.5% at 49.51% 52.06%,
    #fefffd 0%,
    #f4ffef 100%
  );
  --background-steel: linear-gradient(110.23deg, #f2f2ff 5.59%, #fcfffd 94.81%);

  /* shadows */
  --light-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);

  /* fonts */
  --kotori: "Kotori Rose", arial, sans-serif;
  --lexend: "Lexend", sans-serif;
  --roboto: "Roboto Mono", monospace;
}
